import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./Components/Sidebar";
import LoginForm from "./Components/LoginForm";
import SigninForm from "./Components/SigninForm";
import Report from "./Components/Report";
import Main from "./Components/Main";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Header from "./Components/Header";
import UsersTable from "./Components/MyForm";
import Footer from "./Components/Footer";
import EmailActivation from "./Components/EmailActivation";
import SignupGuide from "./Components/SignupGuide";
import ForgetPassword from "./Components/ForgetPassword";
import ResetPassword from "./Components/ResetPassword";
import PasswordGuide from "./Components/PasswordGuide";
import WorldMap from "./Components/WorldMap";

function App() {
  return (
    <div className="app">
      <Router>
        <Routes>
          <Route path="/" element={<LoginForm />} />
          <Route path="/signup" element={<SigninForm />} />
          <Route
            path="/dashboard"
            element={
              <>
                <Sidebar />
                <Header />
                <Main />
              </>
            }
          />
          <Route
            path="/user"
            element={
              <>
                {" "}
                <Sidebar />
                <Header />
                <UsersTable />{" "}
              </>
            }
          />
          <Route
            path="/report"
            element={
              <>
                {" "}
                <Sidebar />
                <Header /> <Report />{" "}
              </>
            }
          />
          <Route path="/emailActivation" element={<EmailActivation />} />
          <Route path="/SignupGuide" element={<SignupGuide />} />
          <Route path="/forgotPassword" element={<ForgetPassword />} />
          <Route path="/forgotPassword-success" element={<PasswordGuide />} />
          <Route path="/set-new-password" element={<ResetPassword />} />
          <Route
            path="/competitive-insights"
            element={
              <>
                {" "}
                <Sidebar />
                <Header /> <WorldMap />{" "}
              </>
            }
          />
        </Routes>
      </Router>

      <Footer />
    </div>
  );
}

export default App;
