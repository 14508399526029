import React, { useState } from "react";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";
import { useEffect } from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";

import {
  colorScale,
  Countries,
  Partner,
  SolutionSellers,
  Specialized,
} from "./Countries";
import ComparativeChart from "./ComparativeChart";
import Sidebar from "./Sidebar";

function Main() {
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [submitVendors, setSubmitVendors] = useState([]);
  const [isH4Visible, setIsH4Visible] = useState(false);



 

  const handleButtonClick = () => {
    setSelectedVendors(submitVendors)
    setIsH4Visible(true);
  };

  const handleVendorSelect = (vendor) => {
    setSubmitVendors((prevSelectedVendors) => {
      if (prevSelectedVendors.includes(vendor)) {
        return prevSelectedVendors.filter((v) => v !== vendor);
      } else {
        return [...prevSelectedVendors, vendor];
      }
    });
   
  };
  useEffect(() => {

  }, []);
  return (
   


    
      <Container fluid className="custom-container ">
      <h5 className='mt-4'>Dashboard</h5>
       
      </Container>
   
  );
}

export default Main;
