import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import React from "react";
import { colorScale,  Countries  ,Partner,SolutionSellers ,Specialized } from "./Countries";
import { Container } from "react-bootstrap";

function WorldMap() {
  return (
  <Container>
    <div className="top-box d-flex">
        <div className="box text-center" style={{backgroundColor:"#FF6969"}}>
            <p>Countries</p>
            <p>6</p>
        </div>
        <div className="box text-center" style={{backgroundColor:"#96C291"}}>
            <p>Partners</p>
            <p>1</p>
        </div>
        <div className="box text-center" style={{backgroundColor:"#FFB7B7"}}>
            <p>Specialized</p>
            <p>1</p>
        </div>
        <div className="box text-center" style={{backgroundColor:"#FFDBAA"}}>
            <p>Solution Sellers</p>
            <p>1</p>
        </div>

    </div>
    <div style={{ margin: "auto", width: "700px", height: "600px" }}>
      <VectorMap
        map={worldMill}
        containerStyle={{
          width: "700px",
          height: "600px",
        }}
        backgroundColor="#282c34"
        markers={[
            ...Countries, 
           ...SolutionSellers,
           ...Specialized,
            ...Partner, 
           
          ]}
          markerStyle={{
            initial: {
              fill: "#FF6969", 
              stroke: "#383f47",
            },
            
          }}

        series={{
          regions: [
            {
              min: 0,
              max: 100,
            },
          ],
        }}
        onRegionTipShow={function reginalTip(event, label, code) {
          return label.html(`
                  <div style="background-color: black; border-radius: 6px; min-height: 50px; width: 125px; color: white"; padding-left: 10px>
                    <p>
                    <b>
                    ${label.html()}
                    </b>
                    </p>
                    <p>
              
                    </p>
                    </div>`);
        }}
        onMarkerTipShow={function markerTip(event, label, code) {
          return label.html(`
                  <div style="background-color: white; border-radius: 6px; min-height: 50px; width: 125px; color: black !important; padding-left: 10px>
                    <p style="color: black !important;">
                    <b>
                    ${label.html()}
                    </b>
                    </p>
                    </div>`);
        }}



        
      />
    </div>
    </Container>
  );
}

export default WorldMap;
