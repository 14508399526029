import React, { useState } from "react";

import { Nav, Navbar, Container, NavDropdown } from "react-bootstrap";

function Header() {



  return (
    <Navbar expand="lg" className="bg-body-tertiary page-top-bar" >
      <Container fluid className="custom-container" style={{position:"fixed", zIndex:"10", top:"0px", backgroundColor:"white",padding:"10px 100px 10px 10px"}}>
        <form className="header-search">
          <button type="submit">
            <i className="fa fa-search"></i>
          </button>
          <input
            type="text"
            placeholder="Search"
            name="search"
          />
        </form>
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          ></Nav>

          <Nav className=" menu-value justify-content-end " style={{marginRight:"60px"}}>
          
            
            <Nav.Link href="#action2" className="bell me-3">
              <img src="images\bellicon.svg" alt="img" className="bell-image" />
            </Nav.Link>
         
            <div className="vr"></div>
            <NavDropdown
              className="ms-4"
              title={
                <div className="profile-icon">
                  <div className="profile-img">
                    <img src="images\profile.svg" alt="img" />
                    <i>
                      {" "}
                      <img src="images\active.svg" alt="img" />
                    </i>
                  </div>
                  robot@legendfootwear.co.uk
                  <span>
                    <img src="images\arrow.svg" alt="drop icon" />
                  </span>
                </div>
              }
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/my-account/profile">
                Profile
              </NavDropdown.Item>
            
              <NavDropdown.Item href="/my-account/notification">
                Notifications
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>

        <Navbar.Toggle aria-controls="navbarScroll" />
      </Container>
    </Navbar>
  );
}

export default Header;
