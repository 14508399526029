import React, { useState } from "react";
import { Button, Form, Container, Row, Col } from "react-bootstrap";
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import { notification, message } from "antd";


function ForgetPassword() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [redirectToMain, setRedirectToMain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const [login, setLogin] = useState("");
 
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleSubmit = (event) => {
    event.preventDefault(); 
    const form = event.currentTarget;

    // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    //   setValidated(true);
    //   setLoading(false);
    //   return;
    // }
    setValidated(true);
    setLoading(true);

    axios
      .post(`https://datastormapi.azurewebsites.net/User/ForgotPassword`, {
        email: email,   
      })
      .then((res) => {
       
        localStorage.setItem("email", email);
        setLogin(res);
        console.log(res.data);

        if (res.data === "Successfully send password reset verification link has been sent to your email address.") {
          notification.success({
            message: "Success",
            description: "Please check your email for further instructions",
          });
          setLoading(false);
          navigate('/forgotPassword-success')
         
        } else if ( res.data === "User not exist in database") {
          notification.warning({
            message: "Warning",
            description: "Invalid User!",
          });
          setLoading(false);
        } else {
          notification.error({
            message: "Error",
            description: "Smothing Wrong!",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  return (
    <div>
      <Container>
        <div className="logo d-flex">
          <img
            src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
            alt="logo"
            className="logosmalls"
          />
          <h3 className="ms-3 mt-2">DataStorm</h3>
        </div>

        <div className="content">
         
         <a href="/" class="list-group-item list-group-item-action  mb-3" style={{color:"rgb(43, 51, 138) "}} ><span className="fs-5"> &#8592;</span> Back to Login</a>
       
         
          <h4>Forgot Password?</h4>
          <p>
            Enter your email, and we'll send you instructions to reset your
            password.
          </p>
         
            <Form
             
              noValidate
              validated={validated}
              className="ms-0"
            >
           <Form.Group className="mb-3" controlId="validationCustom01">
            <Form.Control
              type="email"
              placeholder="Email"
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              Please fill the email.
            </Form.Control.Feedback>
          </Form.Group>

            </Form>
          
            <div className="d-grid">
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              {!loading ? (
                "Send Verfication Link"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default ForgetPassword;
