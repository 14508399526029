import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";

function Report() {
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get("http://188.39.55.174/ReportingAPI/Reports/Products")
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  const columns = [
    {
      field: "manufacturer",
      headerName: "Brand",
      width: 60,
      cellClassName: "custom-first-column-class",
      headerClassName: "custom-first-column-class",
      editable: true,
    },
    { field: "department", headerName: "Dep", width: 50, editable: true },
    { field: "category", headerName: "Category", width: 50, editable: true },
    { field: "colour", headerName: "Color", width: 50, editable: true },
    {
      field: "manufacturerPartNo",
      headerName: "Manu Part No",
      width: 50,
      editable: true,
    },
    { field: "modelName", headerName: "Model Name", width: 50, editable: true },
    {
      field: "width",
      headerName: "Width",
      width: 10,
      cellClassName: "custom-some-column-class",
      headerClassName: "custom-some-column-class",
      editable: true,
    },
    {
      field: "proLastSupplied",
      headerName: "LastSupplied",
      width: 90,
      editable: true,
    },
    {
      field: "proLastCost",
      headerName: "Cost",
      width: 10,
      cellClassName: "custom-some-column-class",
      headerClassName: "custom-some-column-class",
      editable: true,
    },
    {
      field: "fbaStockUkDead",
      headerName: "A-UK REST QTY",
      width: 10,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "fbaStockUkLive",
      headerName: "A-UK LIVE QTY",
      width: 10,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "fbaUkSaleQty",
      headerName: "A-UK SALE",
      width: 10,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "returnsFbaUk",
      headerName: "A-UK Ret",
      width: 10,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "fbaStockUsDead",
      headerName: "A-US REST QTY",
      width: 10,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "fbaStockUsLive",
      headerName: "A-US LIVE QTY",
      width: 10,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "fbaUsSaleQty",
      headerName: "A-US SALE",
      width: 10,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "returnsFbaUs",
      headerName: "A-US Ret",
      width: 10,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "fbaStockDeDead",
      headerName: "A-DE REST QTY",
      width: 10,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "fbaStockDeLive",
      headerName: "A-DE LIVE QTY",
      width: 10,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "A-DE SALE",
      headerName: "A-DE SALE",
      width: 10,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "returnsFbaDe",
      headerName: "A-DE Ret",
      width: 10,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "proStockSto3",
      headerName: "STO 3 QTY",
      width: 10,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "proStockSto2",
      headerName: "STO 2 QTY",
      width: 10,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "proStockSto1",
      headerName: "STO 1 QTY",
      width: 10,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "proSaleSto",
      headerName: "STO Sale",
      width: 10,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "proReturnsSto",
      headerName: "STO Ret",
      width: 10,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "proReturnsStoPercentage",
      headerName: "STO Ret%",
      width: 10,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "proStockStoQ",
      headerName: "STO QTY",
      width: 10,
      cellClassName: "custom-seven-column-class",
      headerClassName: "custom-seven-column-class",
      editable: true,
    },
    {
      field: "proStockXmi",
      headerName: "XMI QTY",
      width: 10,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "proPriceWebsite",
      headerName: "Web Price",
      width: 10,
      cellClassName: "custom-eight-column-class",
      headerClassName: "custom-eight-column-class",
      editable: true,
    },
    {
      field: "All Min",
      headerName: "All Min",
      width: 10,
      cellClassName: "custom-nine-column-class",
      headerClassName: "custom-nine-column-class",
      editable: true,
    },
    {
      field: "All Max",
      headerName: "All Max",
      width: 10,
      cellClassName: "custom-nine-column-class",
      headerClassName: "custom-nine-column-class",
      editable: true,
    },
    {
      field: "FBA Min",
      headerName: "FBA Min",
      width: 10,
      cellClassName: "custom-eleven-column-class",
      headerClassName: "custom-eleven-column-class",
      editable: true,
    },
    {
      field: "FBA Max",
      headerName: "FBA Max",
      width: 10,
      cellClassName: "custom-eleven-column-class",
      headerClassName: "custom-eleven-column-class",
      editable: true,
    },
    {
      field: "priceBelowCost",
      headerName: "Below Cost",
      width: 10,
      cellClassName: "custom-some-column-class",
      headerClassName: "custom-some-column-class",
      editable: true,
    },
    {
      field: "proLastReturnedDate",
      headerName: "Last Return Date",
      width: 50,
      editable: true,
    },
    { field: "productId", headerName: "ProductId", width: 70, editable: true },
    {
      field: "productComments",
      headerName: "Product comment",
      width: 70,
      editable: true,
    },
    {
      field: "nonSaleProduct",
      headerName: "Non Sale Product",
      width: 70,
      editable: true,
    },
    { field: "season", headerName: "Season", width: 70, editable: true },
  ];
  const columns2 = [
    {
      field: "variantCode",
      headerName: "Variant Code",
      width: 10,
      cellClassName: "custom-first-column-class",
      headerClassName: "custom-first-column-class",
      editable: true,
    },
    {
      field: "size",
      headerName: "Size",
      width: 50,
      cellClassName: "custom-some-column-class",
      headerClassName: "custom-some-column-class",
      editable: true,
    },
    {
      field: "varStockFbaUkDead",
      headerName: "A-UK REST QTY",
      width: 50,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "varStockFbaUkLive",
      headerName: "A-UK LIVE QTY",
      width: 50,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "fbaUkSaleQty",
      headerName: "A-UK SALE",
      width: 50,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "returnsFbaUk",
      headerName: "A-UK Ret",
      width: 50,
      cellClassName: "custom-sec-column-class",
      headerClassName: "custom-sec-column-class",
      editable: true,
    },
    {
      field: "varStockFbaUsDead",
      headerName: "A-US REST QTY",
      width: 50,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "varStockFbaUsLive",
      headerName: "A-US LIVE QTY",
      width: 50,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "varFbaUsSaleQty",
      headerName: "A-US SALE",
      width: 50,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "returnsFbaUs",
      headerName: "A-US Ret",
      width: 50,
      cellClassName: "custom-third-column-class",
      headerClassName: "custom-third-column-class",
      editable: true,
    },
    {
      field: "varStockFbaDeDead",
      headerName: "A-DE REST QTY",
      width: 50,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "varStockFbaDeLive",
      headerName: "A-DE LIVE QTY",
      width: 50,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "A-DE SALE",
      headerName: "A-DE SALE",
      width: 50,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "returnsFbaDe",
      headerName: "A-DE Ret",
      width: 50,
      cellClassName: "custom-four-column-class",
      headerClassName: "custom-four-column-class",
      editable: true,
    },
    {
      field: "varStockSto3",
      headerName: "STO 3 QTY",
      width: 50,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "varStockSto2",
      headerName: "STO 2 QTY",
      width: 50,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "varStockSto1",
      headerName: "STO 1 QTY",
      width: 50,
      cellClassName: "custom-five-column-class",
      headerClassName: "custom-five-column-class",
      editable: true,
    },
    {
      field: "varSaleSto",
      headerName: "STO Sale",
      width: 50,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "varReturnsSto",
      headerName: "STO Ret",
      width: 50,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "varStoReturnPercentage",
      headerName: "STO Ret%",
      width: 50,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "varStockXmi",
      headerName: "XMI QTY",
      width: 50,
      cellClassName: "custom-six-column-class",
      headerClassName: "custom-six-column-class",
      editable: true,
    },
    {
      field: "AUK Sale",
      headerName: "AUK Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "ADE Sale",
      headerName: "ADE Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "AUS Sale",
      headerName: "AUS Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "ACA Sale",
      headerName: "ACA Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "EUK Sale",
      headerName: "EUK Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "EUS Sale",
      headerName: "EUS Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "Web Sale",
      headerName: "Web Sale",
      width: 50,
      cellClassName: "custom-ten-column-class",
      headerClassName: "custom-ten-column-class",
      editable: true,
    },
    {
      field: "varPriceWebsite",
      headerName: "Web Price",
      width: 50,
      cellClassName: "custom-eight-column-class",
      headerClassName: "custom-eight-column-class",
      editable: true,
    },
    {
      field: "varStockPatternMin",
      headerName: "All Min",
      width: 50,
      cellClassName: "custom-nine-column-class",
      headerClassName: "custom-nine-column-class",
      editable: true,
    },
    {
      field: "varStockPatternMax",
      headerName: "All Max",
      width: 50,
      cellClassName: "custom-nine-column-class",
      headerClassName: "custom-nine-column-class",
      editable: true,
    },
    {
      field: "FBA Min",
      headerName: "FBA Min",
      width: 50,
      cellClassName: "custom-eleven-column-class",
      headerClassName: "custom-eleven-column-class",
      editable: true,
    },
    {
      field: "FBA Max",
      headerName: "FBA Max",
      width: 50,
      cellClassName: "custom-eleven-column-class",
      headerClassName: "custom-eleven-column-class",
      editable: true,
    },
    {
      field: "varPriceBelowCost",
      headerName: "Below Cost",
      width: 50,
      cellClassName: "custom-some-column-class",
      headerClassName: "custom-some-column-class",
      editable: true,
    },
    {
      field: "varLastReturnedDate",
      headerName: "Last Return Date",
      width: 100,
      editable: true,
    },
  ];

  const rows = [
    {
      id: 1,
      Brand: "Nike",
      Dep: "Sports",
      Category: "Running Shoes",
      Color: "Blue",
      "Manu Part No": "12345",
      "Model Name": "Air Max",
      Width: "M",
      "Last Supplied": "2022-11-28",
      Cost: 50,
      "A-UK REST QTY": 10,
      "A-UK LIVE QTY": 15,
      "A-UK SALE": 8,
      "A-UK Ret": 2,
      "A-US REST QTY": 12,
      "A-US LIVE QTY": 18,
      "A-US SALE": 10,
      "A-US Ret": 3,
      "A-DE REST QTY": 20,
      "A-DE LIVE QTY": 25,
      "A-DE SALE": 15,
      "A-DE Ret": 5,
      "STO 3 QTY": 5,
      "STO 2 QTY": 8,
      "STO 1 QTY": 10,
      "STO Sale": 12,
      "STO Ret": 2,
      "STO Ret%": "20%",
      "STO QTY": 20,
      "XMI QTY": 0,
      "Web Price": 60,
      "All Min": 55,
      "All Max": 65,
      "FBA Min": 48,
      "FBA Max": 58,
      "Below Cost": 45,
      "Last Return Date": "2023-01-15",
    },
    {
      id: 2,
      Brand: "Adidas",
      Dep: "Sports",
      Category: "Soccer Cleats",
      Color: "Black",
      "Manu Part No": "67890",
      "Model Name": "Predator",
      Width: "L",
      "Last Supplied": "2022-11-25",
      Cost: 65,
      "A-UK REST QTY": 15,
      "A-UK LIVE QTY": 20,
      "A-UK SALE": 12,
      "A-UK Ret": 3,
      "A-US REST QTY": 18,
      "A-US LIVE QTY": 22,
      "A-US SALE": 15,
      "A-US Ret": 4,
      "A-DE REST QTY": 25,
      "A-DE LIVE QTY": 30,
      "A-DE SALE": 18,
      "A-DE Ret": 6,
      "STO 3 QTY": 8,
      "STO 2 QTY": 10,
      "STO 1 QTY": 12,
      "STO Sale": 15,
      "STO Ret": 3,
      "STO Ret%": "25%",
      "STO QTY": 25,
      "XMI QTY": 0,
      "Web Price": 75,
      "All Min": 70,
      "All Max": 80,
      "FBA Min": 60,
      "FBA Max": 70,
      "Below Cost": 55,
      "Last Return Date": "2023-02-01",
    },
    {
      id: 3,
      Brand: "Puma",
      Dep: "Athletic",
      Category: "Running Shoes",
      Color: "Red",
      "Manu Part No": "PUM456",
      "Model Name": "Speed Racer",
      Width: "M",
      "Last Supplied": "2023-11-10",
      Cost: 75,
      "A-UK REST QTY": 15,
      "A-UK LIVE QTY": 48,
      "A-UK SALE": 32,
      "A-UK Ret": 6,
      "A-US REST QTY": 20,
      "A-US LIVE QTY": 42,
      "A-US SALE": 22,
      "A-US Ret": 4,
      "A-DE REST QTY": 22,
      "A-DE LIVE QTY": 28,
      "A-DE SALE": 20,
      "A-DE Ret": 7,
      "STO 3 QTY": 18,
      "STO 2 QTY": 15,
      "STO 1 QTY": 22,
      "STO Sale": 20,
      "STO Ret": 6,
      "STO Ret%": "30%",
      "STO QTY": 15,
      "XMI QTY": 0,
      "Web Price": 85,
      "All Min": 72,
      "All Max": 95,
      "FBA Min": 65,
      "FBA Max": 85,
      "Below Cost": 70,
      "Last Return Date": "2023-11-25",
    },
    {
      id: 4,
      Brand: "Reebok",
      Dep: "Casual",
      Category: "Sneakers",
      Color: "White",
      "Manu Part No": "RBK789",
      "Model Name": "Classic",
      Width: "L",
      "Last Supplied": "2023-11-15",
      Cost: 65,
      "A-UK REST QTY": 22,
      "A-UK LIVE QTY": 55,
      "A-UK SALE": 35,
      "A-UK Ret": 3,
      "A-US REST QTY": 18,
      "A-US LIVE QTY": 36,
      "A-US SALE": 18,
      "A-US Ret": 5,
      "A-DE REST QTY": 30,
      "A-DE LIVE QTY": 25,
      "A-DE SALE": 30,
      "A-DE Ret": 9,
      "STO 3 QTY": 16,
      "STO 2 QTY": 18,
      "STO 1 QTY": 24,
      "STO Sale": 22,
      "STO Ret": 8,
      "STO Ret%": "32%",
      "STO QTY": 18,
      "XMI QTY": 0,
      "Web Price": 75,
      "All Min": 68,
      "All Max": 88,
      "FBA Min": 60,
      "FBA Max": 80,
      "Below Cost": 63,
      "Last Return Date": "2023-12-01",
    },
    {
      id: 5,
      Brand: "New Balance",
      Dep: "Athletic",
      Category: "Training Shoes",
      Color: "Gray",
      "Manu Part No": "NB123",
      "Model Name": "Fresh Foam",
      Width: "M",
      "Last Supplied": "2023-11-20",
      Cost: 90,
      "A-UK REST QTY": 25,
      "A-UK LIVE QTY": 60,
      "A-UK SALE": 40,
      "A-UK Ret": 4,
      "A-US REST QTY": 20,
      "A-US LIVE QTY": 45,
      "A-US SALE": 30,
      "A-US Ret": 6,
      "A-DE REST QTY": 28,
      "A-DE LIVE QTY": 35,
      "A-DE SALE": 26,
      "A-DE Ret": 7,
      "STO 3 QTY": 20,
      "STO 2 QTY": 20,
      "STO 1 QTY": 28,
      "STO Sale": 26,
      "STO Ret": 7,
      "STO Ret%": "28%",
      "STO QTY": 15,
      "XMI QTY": 0,
      "Web Price": 95,
      "All Min": 85,
      "All Max": 110,
      "FBA Min": 80,
      "FBA Max": 100,
      "Below Cost": 88,
      "Last Return Date": "2023-12-05",
    },
    {
      id: 6,
      Brand: "Under Armour",
      Dep: "Athletic",
      Category: "Running Shoes",
      Color: "Black",
      "Manu Part No": "UA456",
      "Model Name": "Hovr Sonic",
      Width: "M",
      "Last Supplied": "2023-11-25",
      Cost: 85,
      "A-UK REST QTY": 18,
      "A-UK LIVE QTY": 50,
      "A-UK SALE": 32,
      "A-UK Ret": 3,
      "A-US REST QTY": 15,
      "A-US LIVE QTY": 42,
      "A-US SALE": 28,
      "A-US Ret": 5,
      "A-DE REST QTY": 20,
      "A-DE LIVE QTY": 30,
      "A-DE SALE": 22,
      "A-DE Ret": 6,
      "STO 3 QTY": 15,
      "STO 2 QTY": 18,
      "STO 1 QTY": 25,
      "STO Sale": 24,
      "STO Ret": 6,
      "STO Ret%": "24%",
      "STO QTY": 12,
      "XMI QTY": 0,
      "Web Price": 88,
      "All Min": 80,
      "All Max": 105,
      "FBA Min": 75,
      "FBA Max": 95,
      "Below Cost": 78,
      "Last Return Date": "2023-12-10",
    },
    {
      id: 7,
      Brand: "Skechers",
      Dep: "Casual",
      Category: "Loafers",
      Color: "Brown",
      "Manu Part No": "SK123",
      "Model Name": "Go Walk",
      Width: "L",
      "Last Supplied": "2023-12-01",
      Cost: 60,
      "A-UK REST QTY": 20,
      "A-UK LIVE QTY": 48,
      "A-UK SALE": 30,
      "A-UK Ret": 4,
      "A-US REST QTY": 18,
      "A-US LIVE QTY": 40,
      "A-US SALE": 25,
      "A-US Ret": 3,
      "A-DE REST QTY": 22,
      "A-DE LIVE QTY": 28,
      "A-DE SALE": 20,
      "A-DE Ret": 6,
      "STO 3 QTY": 16,
      "STO 2 QTY": 20,
      "STO 1 QTY": 22,
      "STO Sale": 20,
      "STO Ret": 5,
      "STO Ret%": "25%",
      "STO QTY": 15,
      "XMI QTY": 0,
      "Web Price": 70,
      "All Min": 65,
      "All Max": 85,
      "FBA Min": 60,
      "FBA Max": 80,
      "Below Cost": 58,
      "Last Return Date": "2023-12-15",
    },
    {
      id: 8,
      Brand: "Asics",
      Dep: "Athletic",
      Category: "Running Shoes",
      Color: "Green",
      "Manu Part No": "ASC789",
      "Model Name": "Gel-Kayano",
      Width: "M",
      "Last Supplied": "2023-12-05",
      Cost: 95,
      "A-UK REST QTY": 22,
      "A-UK LIVE QTY": 55,
      "A-UK SALE": 35,
      "A-UK Ret": 3,
      "A-US REST QTY": 18,
      "A-US LIVE QTY": 38,
      "A-US SALE": 20,
      "A-US Ret": 5,
      "A-DE REST QTY": 30,
      "A-DE LIVE QTY": 32,
      "A-DE SALE": 28,
      "A-DE Ret": 9,
      "STO 3 QTY": 18,
      "STO 2 QTY": 22,
      "STO 1 QTY": 26,
      "STO Sale": 24,
      "STO Ret": 7,
      "STO Ret%": "28%",
      "STO QTY": 18,
      "XMI QTY": 0,
      "Web Price": 100,
      "All Min": 90,
      "All Max": 110,
      "FBA Min": 85,
      "FBA Max": 105,
      "Below Cost": 92,
      "Last Return Date": "2023-12-20",
    },
    {
      id: 9,
      Brand: "Vans",
      Dep: "Casual",
      Category: "Skate Shoes",
      Color: "Gray",
      "Manu Part No": "VNS456",
      "Model Name": "Old Skool",
      Width: "L",
      "Last Supplied": "2023-12-10",
      Cost: 75,
      "A-UK REST QTY": 20,
      "A-UK LIVE QTY": 48,
      "A-UK SALE": 30,
      "A-UK Ret": 4,
      "A-US REST QTY": 18,
      "A-US LIVE QTY": 40,
      "A-US SALE": 25,
      "A-US Ret": 3,
      "A-DE REST QTY": 22,
      "A-DE LIVE QTY": 28,
      "A-DE SALE": 20,
      "A-DE Ret": 6,
      "STO 3 QTY": 16,
      "STO 2 QTY": 20,
      "STO 1 QTY": 22,
      "STO Sale": 20,
      "STO Ret": 5,
      "STO Ret%": "25%",
      "STO QTY": 15,
      "XMI QTY": 0,
      "Web Price": 80,
      "All Min": 70,
      "All Max": 90,
      "FBA Min": 65,
      "FBA Max": 85,
      "Below Cost": 68,
      "Last Return Date": "2023-12-25",
    },
  ];
  const rows2 = [
    {
      id: 1,
      "Variant Code": "ABC123",
      Size: "M",
      "A-UK REST QTY": 10,
      "A-UK LIVE QTY": 15,
      "A-UK SALE": 8,
      "A-UK Ret": 2,
      "A-US REST QTY": 12,
      "A-US LIVE QTY": 18,
      "A-US SALE": 10,
      "A-US Ret": 3,
      "A-DE REST QTY": 20,
      "A-DE LIVE QTY": 12,
      "A-DE SALE": 15,
      "A-DE Ret": 5,
      "STO 3 QTY": 5,
      "STO 2 QTY": 8,
      "STO 1 QTY": 10,
      "STO Sale": 12,
      "STO Ret": 2,
      "STO Ret%": "20%",
      "XMI QTY": 0,
      "AUK Sale": 30,
      "ADE Sale": 25,
      "AUS Sale": 28,
      "ACA Sale": 15,
      "EUK Sale": 20,
      "EUS Sale": 18,
      "Web Sale": 35,
      "Web Price": 60,
      "All Min": 55,
      "All Max": 65,
      "FBA Min": 48,
      "FBA Max": 58,
      "Below Cost": 45,
      "Last Return Date": "2023-01-15",
    },
  ];

  const getRowClassName = (params) => {
    console.log(params.id);
    return params.id % 2 === 0 ? "even-row" : "odd-row";
  };

  return (
    <div className="custom-container container-fluid">
      {/* <h5 className='mt-4'>Reports</h5> */}

      <div className="table mt-3">
        <DataGrid
         
          style={{ height: "500px"}}
          rows={data}
          columns={columns}
          hideFooter={true}
        
        />
      </div>

      <div className="specific-products d-flex mb-5 ">
        <div className=" mt-" id="tables">
          <DataGrid
            className="variant-report"
            rows={rows2}
            columns={columns2}
            hideFooter={true}
            getRowClassName={getRowClassName}
          />
        </div>
        <div className="image d-flex align-items-center ms-4 me-2">
          <img
            src="https://m.media-amazon.com/images/I/81r5BDuVz2L._AC_SX575_.jpg"
            height="150"
            width="150"
            alt="shoe"
          ></img>
        </div>
      </div>
    </div>
  );
}

export default Report;

// import React, { useState } from 'react'
// import { Button, Space, Table } from 'antd';

// function Report() {
//   const [selectedRowKeys, setSelectedRowKeys] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [selectedValue, setSelectedValue] = useState('100');

//   const handleDropdownSelect = (value) => {
//     setSelectedValue(value);
//   };

//   const start = () => {
//     setLoading(true);

//     setTimeout(() => {
//       setSelectedRowKeys([]);
//       setLoading(false);
//     }, 1000);
//   };

//   const onSelectChange = (newSelectedRowKeys) => {
//     console.log("selectedRowKeys changed: ", newSelectedRowKeys);
//     setSelectedRowKeys(newSelectedRowKeys);
//   };

//   const rowSelection = {
//     selectedRowKeys,
//     onChange: onSelectChange,
//   };
//   const hasSelected = selectedRowKeys.length > 0;

//   const columns = [
//     {
//       title: 'Brand',
//       dataIndex: 'Brand',
//       width: 50,
//       className: 'custom-first-column-class',
//       editable: true,
//       sorter: (a, b) => a.Brand.localeCompare(b.Brand),
//     },
//     {
//       title: 'Dep',
//       dataIndex: 'Dep',
//       width: 40,
//       editable: true,
//       sorter: (a, b) => a.Dep.localeCompare(b.Dep),
//     },
//     {
//       title: 'Category',
//       dataIndex: 'Category',
//       width: 70,
//       editable: true,
//       sorter: (a, b) => a.Category.localeCompare(b.Category),
//       filters: [
//         { text: 'Category 1', value: 'Category 1' },
//         { text: 'Category 2', value: 'Category 2' },
//         // Add more filters as needed
//       ],
//       onFilter: (value, record) => record.Category === value,
//     },
//     {
//       title: 'Color',
//       dataIndex: 'Color',
//       width: 40,
//       editable: true,
//       sorter: (a, b) => a.Color.localeCompare(b.Color),
//     },
//     {
//       title: 'Manu Part No',
//       dataIndex: 'Manu Part No',
//       width: 70,
//       editable: true,
//       sorter: (a, b) => a['Manu Part No'].localeCompare(b['Manu Part No']),
//     },
//     {
//       title: 'Model Name',
//       dataIndex: 'Model Name',
//       width: 70,
//       editable: true,
//       sorter: (a, b) => a['Model Name'].localeCompare(b['Model Name']),
//     },
//     {
//       title: 'Width',
//       dataIndex: 'Width',
//       width: 10,
//       className: 'custom-some-column-class',
//       editable: true,
//       sorter: (a, b) => a.Width - b.Width,
//     },
//     {
//       title: 'Last Supplied',
//       dataIndex: 'Last Supplied',
//       width: 90,
//       editable: true,
//       sorter: (a, b) => new Date(a['Last Supplied']) - new Date(b['Last Supplied']),
//     },
//     {
//       title: 'Cost',
//       dataIndex: 'Cost',
//       width: 30,
//       className: 'custom-some-column-class',
//       editable: true,
//       sorter: (a, b) => a.Cost - b.Cost,
//     },
//     {
//       title: 'A-UK REST QTY',
//       dataIndex: 'A-UK REST QTY',
//       width: 10,
//       className: 'custom-sec-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-UK REST QTY'] - b['A-UK REST QTY'],
//     },
//     {
//       title: 'A-UK LIVE QTY',
//       dataIndex: 'A-UK LIVE QTY',
//       width: 10,
//       className: 'custom-sec-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-UK LIVE QTY'] - b['A-UK LIVE QTY'],
//     },
//     {
//       title: 'A-UK SALE',
//       dataIndex: 'A-UK SALE',
//       width: 10,
//       className: 'custom-sec-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-UK SALE'] - b['A-UK SALE'],
//     },
//     {
//       title: 'A-UK Ret',
//       dataIndex: 'A-UK Ret',
//       width: 10,
//       className: 'custom-sec-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-UK Ret'] - b['A-UK Ret'],
//     },
//     {
//       title: 'A-US REST QTY',
//       dataIndex: 'A-US REST QTY',
//       width: 10,
//       className: 'custom-third-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-US REST QTY'] - b['A-US REST QTY'],
//     },
//     {
//       title: 'A-US LIVE QTY',
//       dataIndex: 'A-US LIVE QTY',
//       width: 10,
//       className: 'custom-third-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-US LIVE QTY'] - b['A-US LIVE QTY'],
//     },
//     {
//       title: 'A-US SALE',
//       dataIndex: 'A-US SALE',
//       width: 10,
//       className: 'custom-third-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-US SALE'] - b['A-US SALE'],
//     },
//     {
//       title: 'A-US Ret',
//       dataIndex: 'A-US Ret',
//       width: 10,
//       className: 'custom-third-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-US Ret'] - b['A-US Ret'],
//     },
//     {
//       title: 'A-DE REST QTY',
//       dataIndex: 'A-DE REST QTY',
//       width: 10,
//       className: 'custom-four-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-DE REST QTY'] - b['A-DE REST QTY'],
//     },
//     {
//       title: 'A-DE LIVE QTY',
//       dataIndex: 'A-DE LIVE QTY',
//       width: 10,
//       className: 'custom-four-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-DE LIVE QTY'] - b['A-DE LIVE QTY'],
//     },
//     {
//       title: 'A-DE SALE',
//       dataIndex: 'A-DE SALE',
//       width: 10,
//       className: 'custom-four-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-DE SALE'] - b['A-DE SALE'],
//     },
//     {
//       title: 'A-DE Ret',
//       dataIndex: 'A-DE Ret',
//       width: 10,
//       className: 'custom-four-column-class',
//       editable: true,
//       sorter: (a, b) => a['A-DE Ret'] - b['A-DE Ret'],
//     },
//     {
//       title: 'STO 3 QTY',
//       dataIndex: 'STO 3 QTY',
//       width: 10,
//       className: 'custom-five-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO 3 QTY'] - b['STO 3 QTY'],
//     },
//     {
//       title: 'STO 2 QTY',
//       dataIndex: 'STO 2 QTY',
//       width: 10,
//       className: 'custom-five-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO 2 QTY'] - b['STO 2 QTY'],
//     },
//     {
//       title: 'STO 1 QTY',
//       dataIndex: 'STO 1 QTY',
//       width: 10,
//       className: 'custom-five-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO 1 QTY'] - b['STO 1 QTY'],
//     },
//     {
//       title: 'STO Sale',
//       dataIndex: 'STO Sale',
//       width: 10,
//       className: 'custom-six-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO Sale'] - b['STO Sale'],
//     },
//     {
//       title: 'STO Ret',
//       dataIndex: 'STO Ret',
//       width: 10,
//       className: 'custom-six-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO Ret'] - b['STO Ret'],
//     },
//     {
//       title: 'STO Ret%',
//       dataIndex: 'STO Ret%',
//       width: 10,
//       className: 'custom-six-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO Ret%'] - b['STO Ret%'],
//     },
//     {
//       title: 'STO QTY',
//       dataIndex: 'STO QTY',
//       width: 10,
//       className: 'custom-seven-column-class',
//       editable: true,
//       sorter: (a, b) => a['STO QTY'] - b['STO QTY'],
//     },
//     {
//       title: 'XMI QTY',
//       dataIndex: 'XMI QTY',
//       width: 10,
//       className: 'custom-six-column-class',
//       editable: true,
//       sorter: (a, b) => a['XMI QTY'] - b['XMI QTY'],
//     },
//     {
//       title: 'Web Price',
//       dataIndex: 'Web Price',
//       width: 10,
//       className: 'custom-eight-column-class',
//       editable: true,
//       sorter: (a, b) => a['Web Price'] - b['Web Price'],
//     },
//     {
//       title: 'All Min',
//       dataIndex: 'All Min',
//       width: 10,
//       className: 'custom-nine-column-class',
//       editable: true,
//       sorter: (a, b) => a['All Min'] - b['All Min'],
//     },
//     {
//       title: 'All Max',
//       dataIndex: 'All Max',
//       width: 10,
//       className: 'custom-nine-column-class',
//       editable: true,
//       sorter: (a, b) => a['All Max'] - b['All Max'],
//     },
//     {
//       title: 'FBA Min',
//       dataIndex: 'FBA Min',
//       width: 10,
//       className: 'custom-eleven-column-class',
//       editable: true,
//       sorter: (a, b) => a['FBA Min'] - b['FBA Min'],
//     },
//     {
//       title: 'FBA Max',
//       dataIndex: 'FBA Max',
//       width: 10,
//       className: 'custom-eleven-column-class',
//       editable: true,
//       sorter: (a, b) => a['FBA Max'] - b['FBA Max'],
//     },
//     {
//       title: 'Below Cost',
//       dataIndex: 'Below Cost',
//       width: 10,
//       className: 'custom-some-column-class',
//       editable: true,
//       sorter: (a, b) => a['Below Cost'] - b['Below Cost'],
//     },
//     {
//       title: 'Last Return Date',
//       dataIndex: 'Last Return Date',
//       width: 80,
//       editable: true,
//       sorter: (a, b) => new Date(a['Last Return Date']) - new Date(b['Last Return Date']),
//     },
//   ];

//    const data = [
//     { key: 1, Brand: 'Nike', Dep: 'Sports', Category: 'Running Shoes', Color: 'Blue', 'Manu Part No': '12345', 'Model Name': 'Air Max', Width: 'M', 'Last Supplied': '2022-11-28', Cost: 50, 'A-UK REST QTY': 10, 'A-UK LIVE QTY': 15, 'A-UK SALE': 8, 'A-UK Ret': 2, 'A-US REST QTY': 12, 'A-US LIVE QTY': 18, 'A-US SALE': 10, 'A-US Ret': 3, 'A-DE REST QTY': 20, 'A-DE LIVE QTY': 25, 'A-DE SALE': 15, 'A-DE Ret': 5, 'STO 3 QTY': 5, 'STO 2 QTY': 8, 'STO 1 QTY': 10, 'STO Sale': 12, 'STO Ret': 2, 'STO Ret%': '20%', 'STO QTY': 20, 'XMI QTY': 0, 'Web Price': 60, 'All Min': 55, 'All Max': 65, 'FBA Min': 48, 'FBA Max': 58, 'Below Cost': 45, 'Last Return Date': '2023-01-15' },
//      { key: 2, Brand: 'Adidas', Dep: 'Sports', Category: 'Soccer Cleats', Color: 'Black', 'Manu Part No': '67890', 'Model Name': 'Predator', Width: 'L', 'Last Supplied': '2022-11-25', Cost: 65, 'A-UK REST QTY': 15, 'A-UK LIVE QTY': 20, 'A-UK SALE': 12, 'A-UK Ret': 3, 'A-US REST QTY': 18, 'A-US LIVE QTY': 22, 'A-US SALE': 15, 'A-US Ret': 4, 'A-DE REST QTY': 25, 'A-DE LIVE QTY': 30, 'A-DE SALE': 18, 'A-DE Ret': 6, 'STO 3 QTY': 8, 'STO 2 QTY': 10, 'STO 1 QTY': 12, 'STO Sale': 15, 'STO Ret': 3, 'STO Ret%': '25%', 'STO QTY': 25, 'XMI QTY': 0, 'Web Price': 75, 'All Min': 70, 'All Max': 80, 'FBA Min': 60, 'FBA Max': 70, 'Below Cost': 55, 'Last Return Date': '2023-02-01' },
//      { key: 3, Brand: 'Puma', Dep: 'Athletic', Category: 'Running Shoes', Color: 'Red', 'Manu Part No': 'PUM456', 'Model Name': 'Speed Racer', Width: 'M', 'Last Supplied': '2023-11-10', Cost: 75, 'A-UK REST QTY': 15, 'A-UK LIVE QTY': 48, 'A-UK SALE': 32, 'A-UK Ret': 6, 'A-US REST QTY': 20, 'A-US LIVE QTY': 42, 'A-US SALE': 22, 'A-US Ret': 4, 'A-DE REST QTY': 22, 'A-DE LIVE QTY': 28, 'A-DE SALE': 20, 'A-DE Ret': 7, 'STO 3 QTY': 18, 'STO 2 QTY': 15, 'STO 1 QTY': 22, 'STO Sale': 20, 'STO Ret': 6, 'STO Ret%': '30%', 'STO QTY': 15, 'XMI QTY': 0, 'Web Price': 85, 'All Min': 72, 'All Max': 95, 'FBA Min': 65, 'FBA Max': 85, 'Below Cost': 70, 'Last Return Date': '2023-11-25' },
//     { key: 4, Brand: 'Reebok', Dep: 'Casual', Category: 'Sneakers', Color: 'White', 'Manu Part No': 'RBK789', 'Model Name': 'Classic', Width: 'L', 'Last Supplied': '2023-11-15', Cost: 65, 'A-UK REST QTY': 22, 'A-UK LIVE QTY': 55, 'A-UK SALE': 35, 'A-UK Ret': 3, 'A-US REST QTY': 18, 'A-US LIVE QTY': 36, 'A-US SALE': 18, 'A-US Ret': 5, 'A-DE REST QTY': 30, 'A-DE LIVE QTY': 25, 'A-DE SALE': 30, 'A-DE Ret': 9, 'STO 3 QTY': 16, 'STO 2 QTY': 18, 'STO 1 QTY': 24, 'STO Sale': 22, 'STO Ret': 8, 'STO Ret%': '32%', 'STO QTY': 18, 'XMI QTY': 0, 'Web Price': 75, 'All Min': 68, 'All Max': 88, 'FBA Min': 60, 'FBA Max': 80, 'Below Cost': 63, 'Last Return Date': '2023-12-01' },
//      { key: 5, Brand: 'New Balance', Dep: 'Athletic', Category: 'Training Shoes', Color: 'Gray', 'Manu Part No': 'NB123', 'Model Name': 'Fresh Foam', Width: 'M', 'Last Supplied': '2023-11-20', Cost: 90, 'A-UK REST QTY': 25, 'A-UK LIVE QTY': 60, 'A-UK SALE': 40, 'A-UK Ret': 4, 'A-US REST QTY': 20, 'A-US LIVE QTY': 45, 'A-US SALE': 30, 'A-US Ret': 6, 'A-DE REST QTY': 28, 'A-DE LIVE QTY': 35, 'A-DE SALE': 26, 'A-DE Ret': 7, 'STO 3 QTY': 20, 'STO 2 QTY': 20, 'STO 1 QTY': 28, 'STO Sale': 26, 'STO Ret': 7, 'STO Ret%': '28%', 'STO QTY': 15, 'XMI QTY': 0, 'Web Price': 95, 'All Min': 85, 'All Max': 110, 'FBA Min': 80, 'FBA Max': 100, 'Below Cost': 88, 'Last Return Date': '2023-12-05' },
//     { key: 6, Brand: 'Under Armour', Dep: 'Athletic', Category: 'Running Shoes', Color: 'Black', 'Manu Part No': 'UA456', 'Model Name': 'Hovr Sonic', Width: 'M', 'Last Supplied': '2023-11-25', Cost: 85, 'A-UK REST QTY': 18, 'A-UK LIVE QTY': 50, 'A-UK SALE': 32, 'A-UK Ret': 3, 'A-US REST QTY': 15, 'A-US LIVE QTY': 42, 'A-US SALE': 28, 'A-US Ret': 5, 'A-DE REST QTY': 20, 'A-DE LIVE QTY': 30, 'A-DE SALE': 22, 'A-DE Ret': 6, 'STO 3 QTY': 15, 'STO 2 QTY': 18, 'STO 1 QTY': 25, 'STO Sale': 24, 'STO Ret': 6, 'STO Ret%': '24%', 'STO QTY': 12, 'XMI QTY': 0, 'Web Price': 88, 'All Min': 80, 'All Max': 105, 'FBA Min': 75, 'FBA Max': 95, 'Below Cost': 78, 'Last Return Date': '2023-12-10' },
//      { key: 7, Brand: 'Skechers', Dep: 'Casual', Category: 'Loafers', Color: 'Brown', 'Manu Part No': 'SK123', 'Model Name': 'Go Walk', Width: 'L', 'Last Supplied': '2023-12-01', Cost: 60, 'A-UK REST QTY': 20, 'A-UK LIVE QTY': 48, 'A-UK SALE': 30, 'A-UK Ret': 4, 'A-US REST QTY': 18, 'A-US LIVE QTY': 40, 'A-US SALE': 25, 'A-US Ret': 3, 'A-DE REST QTY': 22, 'A-DE LIVE QTY': 28, 'A-DE SALE': 20, 'A-DE Ret': 6, 'STO 3 QTY': 16, 'STO 2 QTY': 20, 'STO 1 QTY': 22, 'STO Sale': 20, 'STO Ret': 5, 'STO Ret%': '25%', 'STO QTY': 15, 'XMI QTY': 0, 'Web Price': 70, 'All Min': 65, 'All Max': 85, 'FBA Min': 60, 'FBA Max': 80, 'Below Cost': 58, 'Last Return Date': '2023-12-15' },
//      { key: 8, Brand: 'Asics', Dep: 'Athletic', Category: 'Running Shoes', Color: 'Green', 'Manu Part No': 'ASC789', 'Model Name': 'Gel-Kayano', Width: 'M', 'Last Supplied': '2023-12-05', Cost: 95, 'A-UK REST QTY': 22, 'A-UK LIVE QTY': 55, 'A-UK SALE': 35, 'A-UK Ret': 3, 'A-US REST QTY': 18, 'A-US LIVE QTY': 38, 'A-US SALE': 20, 'A-US Ret': 5, 'A-DE REST QTY': 30, 'A-DE LIVE QTY': 32, 'A-DE SALE': 28, 'A-DE Ret': 9, 'STO 3 QTY': 18, 'STO 2 QTY': 22, 'STO 1 QTY': 26, 'STO Sale': 24, 'STO Ret': 7, 'STO Ret%': '28%', 'STO QTY': 18, 'XMI QTY': 0, 'Web Price': 100, 'All Min': 90, 'All Max': 110, 'FBA Min': 85, 'FBA Max': 105, 'Below Cost': 92, 'Last Return Date': '2023-12-20' },
//     { key: 9, Brand: 'Vans', Dep: 'Casual', Category: 'Skate Shoes', Color: 'Gray', 'Manu Part No': 'VNS456', 'Model Name': 'Old Skool', Width: 'L', 'Last Supplied': '2023-12-10', Cost: 75, 'A-UK REST QTY': 20, 'A-UK LIVE QTY': 48, 'A-UK SALE': 30, 'A-UK Ret': 4, 'A-US REST QTY': 18, 'A-US LIVE QTY': 40, 'A-US SALE': 25, 'A-US Ret': 3, 'A-DE REST QTY': 22, 'A-DE LIVE QTY': 28, 'A-DE SALE': 20, 'A-DE Ret': 6, 'STO 3 QTY': 16, 'STO 2 QTY': 20, 'STO 1 QTY': 22, 'STO Sale': 20, 'STO Ret': 5, 'STO Ret%': '25%', 'STO QTY': 15, 'XMI QTY': 0, 'Web Price': 80, 'All Min': 70, 'All Max': 90, 'FBA Min': 65, 'FBA Max': 85, 'Below Cost': 68, 'Last Return Date': '2023-12-25' },
//    ];
//   return (
//     <div className='custom-container container-fluid mt-2'>

// <Table

//        className="mt-3"
//        rowSelection={rowSelection}
//        columns={columns}
//        dataSource={data}
//        scroll={{ x: 'max-content' }}

//      />

//     </div>
//   )
// }

// export default Report
