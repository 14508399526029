import React, { useState } from "react";
import {
  Container,
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Dropdown,
} from "react-bootstrap";
import { RiPencilFill, RiDeleteBin6Fill } from "react-icons/ri";
import axios from "axios";
import { useEffect } from "react";
import {
  faEye,
  faEyeSlash,
  faLongArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import { Spin, Space, Popconfirm } from "antd";


const UsersTable = () => {
  const [users, setUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAproveModal, setShowAproveModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "",
    status: "",
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [adminId, setAdminId] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [user, setUser] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [permission, setPermission] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [vendorValue, setVendorValue] = useState([]);
  const [approveVendors, setApproveVendors] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [roleId] = useState(localStorage.getItem("roleId"));
  const vendorFromLocalStorage = JSON.parse(localStorage.getItem("vendor"));
  const [vendor, setVendor] = useState(vendorFromLocalStorage);
  const [selectedEditUserVendor , setselectedEditUserVendor] = useState([])

  const [editedUser, setEditedUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    permissionDto: [],
    vendors: [],
  });

  useEffect(() => {
    const adminId = localStorage.getItem("adminId");
    setAdminId(adminId);

    axios.get("https://datastormapi.azurewebsites.net/Vendor").then((res) => {
      setVendorValue(res.data);
    });

    axios
      .get("https://datastormapi.azurewebsites.net/Permission")
      .then((res) => {
        const permissionss = res.data.map((e) => ({
          id: e.id,
          permission: e.permission,
        }));
        setPermission(permissionss);
      });
    setIsLoading(true);

    axios
      .get(`https://datastormapi.azurewebsites.net/User/All?Id=${adminId}`)
      .then((res) => {
        setIsLoading(false);
        setUserDetails(res.data.userInfo);
      });
  }, []);

  const getUserDetails = () => {
    axios
      .get(`https://datastormapi.azurewebsites.net/User/All?Id=${adminId}`)
      .then((res) => {
        setUserDetails(res.data.userInfo);
      });
  };

  const handleDropdownChange = (vendor) => {
    const vendorIndex = approveVendors.findIndex((p) => p.vendor === vendor);
    if (vendorIndex !== -1) {
      const updatedVendor = { ...approveVendors[vendorIndex] };
      updatedVendor.isApproved = !updatedVendor.isApproved;
      const updatedVendors = [...approveVendors];
      updatedVendors[vendorIndex] = updatedVendor;
      setApproveVendors(updatedVendors);
    }
  };

  const transformedVendors = selectedVendors.map((vendor) => ({
    vendor: vendor,
  }));

  const handleDropdownChange1 = (vendor) => {
    if (selectedVendors.includes(vendor)) {
      setSelectedVendors(selectedVendors.filter((v) => v !== vendor));
    } else {
      setSelectedVendors([...selectedVendors, vendor]);
    }
  };

  const handlePermissionChange = (permission) => {
    if (selectedPermissions.includes(permission.id)) {
      setSelectedPermissions(
        selectedPermissions.filter((id) => id !== permission.id)
      );
    } else {
      setSelectedPermissions([...selectedPermissions, permission.id]);
    }
  };

  const handleEditPermissionChange = (selectedPermission) => {
    const permissionId = selectedPermission.id;
    const isSelected = editedUser.permissionDto.some(
      (p) => p.id === permissionId
    );

    if (isSelected) {
      setEditedUser({
        ...editedUser,
        permissionDto: editedUser.permissionDto.filter(
          (p) => p.id !== permissionId
        ),
      });
    } else {
      setEditedUser({
        ...editedUser,
        permissionDto: [
          ...editedUser.permissionDto,
          { id: permissionId, permission: selectedPermission.permission },
        ],
      });
    }
  };
  
  const handleEditVendorDropdownChange = (vendorName) => {
    setEditedUser((prevUser) => {
      const updatedVendors = prevUser.vendors.slice(); // Create a copy of the vendors array
  
      // Find the index of the vendor with the specified name
      const index = updatedVendors.findIndex((permission) => permission.vendor === vendorName);
  
      if (index !== -1) {
        // If the vendor is found, unselect it (set "false")
        updatedVendors.splice(index, 1);
      } else {
        // If the vendor is not found, add it with "true" status
        updatedVendors.push({ isApproved: true, vendor: vendorName });
      }
  
      return {
        ...prevUser,
        vendors: updatedVendors
      };
    });
  };

  const handleApproveClick = () => {
    const updatedVendors = approveVendors.map((vendorObj) => ({
      ...vendorObj,
      isApproved: selectedVendors.includes(vendorObj.vendor)
        ? !vendorObj.isApproved
        : vendorObj.isApproved,
    }));
    setShowAproveModal(false);
    axios
      .put("https://datastormapi.azurewebsites.net/User/VendorApproved", {
        id: userId,
        vendors: updatedVendors,
      })
      .then((res) => {
        getUserDetails();
        notification.success({
          message: "Success",
          description: "Approved Successfully!",
        });
      });

    setSelectedVendors([]);
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedUserId(null);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      role: "",
      status: "",
    });
  };

  const handlEditClose = () => {
    setShowEditModal(false);
  };
  const handleAproveClose = () => {
    setShowAproveModal(false);
  };

  const handleShow = () => setShowModal(true);
  const handleEditShow = () => setShowEditModal(true);

  const handleAproveShow = (user) => {
    setUserId(user.id);

    setApproveVendors(user.vendors);
    console.log(approveVendors);
    setShowAproveModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddUser = () => {
    setIsLoading(true);
    if (!adminId) {
      notification.error({
        message: "Error",
        description: "Admin ID is missing or invalid!",
      });
      return;
    }
    setShowModal(false);
    axios
      .post("https://datastormapi.azurewebsites.net/User", {
        email: email,
        firstName: firstName,
        lastName: lastName,
        password: password,
        vendors: transformedVendors,
        adminId: adminId,
        permissionId: selectedPermissions,
      })
      .then((res) => {
        setUser(res.data);
       
        if (res.data === "User Already Exists in the Database") {
          setIsLoading(false);
          setSelectedVendors([]);
          notification.warning({
            message: "Warning",
            description: "User Already Exists in the Database!",
          });
        } else if (res.data === "User Created Successfully") {
          setShowModal(false);
          setSelectedVendors([]);
          setIsLoading(false);
          notification.success({
            message: "Success",
            description: "User Created Successfully!",
          });
          getUserDetails();
          setEmail("");
          setFirstName("");
          setLastName("");
          setPassword("");

          setSelectedPermissions([]);
        }
      })
      .catch((error) => {
        setShowModal(false);
        notification.error({
          message: "Error",
          description: "Something went wrong while creating the user.",
        });
        console.error(error);
      });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleEditUser = () => {
    setShowEditModal(false)
    axios
      .put("https://datastormapi.azurewebsites.net/User", {
        id:editedUser.id,
        permissions: editedUser.permissionDto,
        vendors: editedUser.vendors,
      })
      .then((res) => {
        console.log(res);
      
       if(res.data == "SuccessFully updated User vendors and permissions"){
        getUserDetails();
        notification.success({
          message: "Success",
          description: "User updated successfully!",
        });
       }
       else if(res.data =="Please Provided a Valid Input"){
        notification.warning({
          message: "Warning",
          description: "Please give us a valid input",
        });
       }
      });
    handleClose();
  };

  const handleEditClick = (user) => {
    
    setShowEditModal(true);
    setEditedUser(user);
  };

  const handleDeleteUser = (userId) => {
    axios
      .delete(
        `https://datastormapi.azurewebsites.net/User?Id=${adminId}&UserId=${userId}`
      )
      .then((res) => {
        if (res.data == "User deleted successfully ") {
          getUserDetails();
          notification.success({
            message: "Success",
            description: "User Deleted Successfully",
          });
          getUserDetails();
        }
      });
  };

 

  return (
    <Container fluid className=" custom-container mt-5 mb-5">
      {roleId != 3 ? (
        <>
          <Button variant="primary" className="mb-3" onClick={handleShow}>
            <span>+</span> Add User
          </Button>

          <div className="example"></div>

          {/*   Add User Model */}
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                {selectedUserId ? "Edit User" : "Add User"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom05">
                      <Form.Control
                        type="text"
                        placeholder="First name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the First name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom06">
                      <Form.Control
                        type="text"
                        placeholder="Last name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the Last name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom08">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the Email .
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 d-flex" controlId="validationCustom07">
                      <Form.Control
                        type={isPasswordVisible ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the Password.
                      </Form.Control.Feedback>

                      <span
                  className="edit-showPassword text-end"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isPasswordVisible ? faEye : faEyeSlash}
                  />
                </span>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="d-flex">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom09">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="triggerIds"
                          className="custom-dropdown-toggle"
                        >
                          {selectedPermissions.length === 0
                            ? "Permissions"
                            : "Permissions"}{" "}
                          <span className="icon ms-1" id="arrorw">
                            &#9663;
                          </span>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {permission.map((p) => (
                            <Dropdown.Item
                              key={p.id}
                              onClick={(e) => {
                                handlePermissionChange(p);
                                e.stopPropagation();
                              }}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={selectedPermissions.includes(p.id)}
                                onClick={(e) => {
                                  handlePermissionChange(p);
                                  e.stopPropagation();
                                }}
                              />
                              {p.permission}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Dropdown
                      onSelect={(eventKey) => handleDropdownChange1(eventKey)}
                    >
                      <Dropdown.Toggle
                        variant=""
                        id="triggerIds"
                        className="custom-dropdown-toggle"
                      >
                        Vendors
                        <span className="icon ms-1" id="arrorw">
                          &#9663;
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {vendor
                          .filter((p) => p.isApproved)
                          .map((p) => (
                            <Dropdown.Item
                              key={p.id}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDropdownChange1(p.vendor);
                              }}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={selectedVendors.includes(p.vendor)}
                                onChange={() => handleDropdownChange1(p.vendor)}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDropdownChange1(p.vendor);
                                }}
                              />
                              {p.vendor}
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleAddUser}>
                Add User
              </Button>
            </Modal.Footer>
          </Modal>

          {/*   Edit User Model */}

          <Modal show={showEditModal} onHide={handlEditClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row className="mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom08">
                      <Form.Control
                        disabled
                        type="email"
                        placeholder="Email"
                        required
                        value={editedUser.email}
                        onChange={(e) =>
                          setEditedUser({
                            ...editedUser,
                            email: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the Email .
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom05">
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="First name"
                        value={editedUser.firstName}
                        onChange={(e) =>
                          setEditedUser({
                            ...editedUser,
                            firstName: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the First name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom06">
                      <Form.Control
                        type="text"
                        disabled
                        placeholder="Last name"
                        value={editedUser.lastName}
                        onChange={(e) =>
                          setEditedUser({
                            ...editedUser,
                            lastName: e.target.value,
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please fill the Last name.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="d-flex">
                  <Col>
                    <Form.Group className="mb-3" controlId="validationCustom09">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          id="triggerIds"
                          className="custom-dropdown-toggle"
                        >
                          {selectedPermissions.length === 0
                            ? "Permissions"
                            : "Permissions"}{" "}
                          <span className="icon ms-1" id="arrorw">
                            &#9663;
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {permission.map((p) => (
                            <Dropdown.Item
                              key={p.id}
                              onClick={(e) => {
                                handleEditPermissionChange(p);
                                e.stopPropagation();
                              }}
                            >
                              <input
                                className="me-2"
                                type="checkbox"
                                checked={editedUser.permissionDto.some(
                                  (permission) => permission.id === p.id
                                )}
                                onClick={(e) => {
                                  handleEditPermissionChange(p);
                                  e.stopPropagation();
                                }}
                              />
                              {p.permission}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        id="triggerIds"
                        className="custom-dropdown-toggle"
                      >
                        Vendors
                        <span className="icon ms-1" id="arrorw">
                          &#9663;
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {vendor
                          .filter((p) => p.isApproved)
                          .map((p) => (
                            <Dropdown.Item
                              key={p.id}
                              onChange={() =>
                                handleEditVendorDropdownChange(p.vendor)
                              }
                              onClick={(e) => {e.stopPropagation(); handleEditVendorDropdownChange(p.vendor)
                              }}
                            >
                              <label>
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  checked={editedUser.vendors.some(
                                    (permission) =>
                                      permission.vendor === p.vendor
                                  )}
                                  onClick={(e) => {e.stopPropagation(); handleEditVendorDropdownChange(p.vendor)
                                  }}
                                  onChange={() =>
                                    handleEditVendorDropdownChange(p.vendor)
                                  }
                                />
                                {p.vendor}
                              </label>
                            </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleEditUser}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>

          {/*  Approve vendor Model */}
          <Modal show={showAproveModal} onHide={handleAproveClose}>
            <Modal.Header closeButton>
              <Modal.Title>Approve Vendor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                {approveVendors.map((p) => (
                  <p
                    key={p.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDropdownChange(p.vendor);
                    }}
                   
                  >
                    <input
                      type="checkbox"
                      checked={
                        p.isApproved || selectedVendors.includes(p.vendor)
                      }
                      onChange={() => handleDropdownChange(p.vendor)}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownChange(p.vendor);
                      }}
                    />{" "}
                    {p.vendor}
                  </p>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleAproveClose}>
                Close
              </Button>

              <Button variant="primary" onClick={handleApproveClick}>
                Approve
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  {/* <th className="text-center">#</th> */}
                  <th className="text-center">First Name</th>
                  <th className="text-center">Last Name</th>
                  <th className="text-center">Email</th>
                  <th className="text-center">
                    {roleId == 1 ? "Approval status" : null}
                  </th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Spin size="large" tip="Loading..." className="spin" />
                  </Space>
                ) : userDetails.length === 0 ? (
                  <div className="noUser">No Users</div>
                ) : (
                  userDetails.map((user) => (
                    <tr key={user.id}>
                      <td className="text-center">{user.firstName}</td>
                      <td className="text-center">{user.lastName}</td>
                      <td className="text-center">{user.email}</td>
                      <td className="text-center">
                        {roleId == 1 ? (
                          user.vendorApproved ? (
                            <img
                              src="images/check.png"
                              alt="Approved"
                              height="20"
                              width="20"
                            />
                          ) : (
                            <img
                              src="images/time.png"
                              alt="Not Approved"
                              height="20"
                              width="20"
                            />
                          )
                        ) : null}
                      </td>
                      <td className="buttonedit text-center">
                      {roleId !=1 &&(
                        <Button
                      
                          className="edit-button"
                          onClick={() => handleEditClick(user)}
                        >
                          <RiPencilFill className="button-icon" />
                        </Button>)}
                        
                        {roleId == 1 && (
                          <Button
                            className="edit-button"
                            onClick={() => handleAproveShow(user)}
                          >
                            Approve
                          </Button>
                        )}
                        <Popconfirm
                          title="Are you sure you want to delete this user?"
                          onConfirm={() => handleDeleteUser(user.id)}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button className="edit-button">
                            <RiDeleteBin6Fill className="button-icon" />
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h4>You are not allowed to access this page.</h4>
        </div>
      )}
    </Container>
  );
};

export default UsersTable;
