import React from 'react'

function Footer() {
  return (
    <div>  
   <footer class="footer">
    
    <span>Copyright &copy; 2023<a href="#"> Legend Footwear &reg;</a>.</span>
</footer></div>
  )
}

export default Footer