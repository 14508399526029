export const Countries = [
    { name: "Singapore", latLng: [1.3521, 103.8198] },
    { name: "BouvetIsland", latLng: [54.4208, 3.3464] },
    { name: "Bermuda", latLng: [32.3078, 64.7505] },
    { name: "Andorra", latLng: [42.5063, 1.5218] },
    { name: "AmericanSamoa", latLng: [14.271, 170.1322] },
    { name: "ÅlandIslands", latLng: [60.1785, 19.9156] },
  ];

  export const Partner = [
    { name: "Chaina", latLng: [35.8617, 104.1954] , style: {fill: '#96C291'}},
   
  ];
  export const Specialized = [
    { name: "Canada", latLng: [35.8617, 106.3468] , style: {fill: '#FFB7B7'}},
   
  ];
  export const SolutionSellers = [
    { name: " Afghanistan", latLng: [33.9391, 67.7100] , style: {fill: '#FFDBAA'}},
   
  ];
  
  export const countries = {
    IN: 88,
    CN: 33,
    RU: 79,
    MY: 2,
    GB: 100,
    FK: 10,
    AR: 50,
    VE: 90,
  };
  
  export const colorScale = ["#E2AEFF", "#5E32CA"];


  

