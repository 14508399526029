import React from 'react'
import { Container } from "react-bootstrap";

function SignupGuide() {
  return (
    <div>
        <Container>
        <div className="logo d-flex">
          <img
            src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
            alt="logo"
            className="logosmalls"
          />
          <h3 className="ms-3 mt-2">DataStorm</h3>
        </div>

        <div className="content">
              <h5>To ensure that your account is activated, please complete the email verification process.</h5>
              <p className=''>Kindly check your email for further instructions.</p>
         
            
         
           </div>


        </Container>

    </div>
  )
}

export default SignupGuide