import React from "react";
import { Link } from "react-router-dom";

function Sidebar() {
  return (
    <div id="sideMenubar" className="sidebar">
      <div className="header-wrapper">
        <div className="brand-logo">
          <h4 className="logolarge">Legend Footwear</h4>
        </div>
      </div>
      <nav>
        <ul className="menus">
          <li
            className={`menu-items ${
              window.location.pathname === "/dashboard" ? "active" : ""
            }`}
          >
            <Link to="/dashboard">
              <img
                src="images/home3.png"
                alt="drop icon"
                className="white-icon"
                width="20px"
                height="20px"
              />
              <span className="ms-2">Dashboard</span>
            </Link>
          </li>

          <li
            className={`menu-items  ${
              window.location.pathname === "/report" ? "active" : ""
            }`}
          >
            <Link>
              <img
                src="images\business-report.png"
                alt="drop icon"
                className="white-icon"
                width="20px"
                height="20px"
              />
              <span className="ms-2">Report</span>
            </Link>
            <ul className="submenu-dropdown mt-2">
              <li
                className={`menu-items ${
                  window.location.pathname === "/report"
                }`}
              >
                <Link to="/report">
                  <span style={{ fontSize: "13px"}}>
                    {" "}
                    &#8594;Products Report
                  </span>
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="menus bottom">
          <div className="hr-line-container"></div>
          <li className="menu-items">
            <Link>
              <img
                src="images\7thicon.svg"
                alt="drop icon"
                className="white-icon"
              />
              <span className="ms-2">Settings</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Sidebar;
