import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { notification,message } from "antd";

function ResetPassword() {
  const [validated, setValidated] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [token ,setToken] =useState("");
  const navigate = useNavigate();

  const handleSubmit = () => {
    setLoading(true);
    axios
    .post("https://datastormapi.azurewebsites.net/User/ResetPassword",{
        token : token,
        password : password
    })
    .then((res) =>{
      if(res.data == "Password SucessFully Updated"){
        setLoading(false);
        notification.success({
            message: "Success",
            description: "Your password has been successfully reset.",
          });
          navigate("/");
    }
    else{
        setLoading(false);
        notification.error({
            message: "Error",
            description: "Somthing Wrong!",
          });
    }
    
    })


  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const tokens = searchParams.get('token');
    setToken(tokens);
    console.log(token);
  }, []);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  return (
    <div>
      <Container>
        <div className="logo d-flex">
          <img
            src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
            alt="logo"
            className="logosmalls"
          />
          <h3 className="ms-3 mt-2">DataStorm</h3>
        </div>

        <div className="content">
          <h4>Set New Password</h4>
          <p>Enter your new password </p>
          <Form
          
            noValidate
            validated={validated}
            className="ms-0"
          >
            <div className="mb-0 password-cont">
              <Form.Group className="mb-3" controlId="validationCustom07">
                <Form.Control
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please fill the Password.
                </Form.Control.Feedback>

                <span
                  className="show-password"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isPasswordVisible ? faEyeSlash : faEye}
                  />
                </span>
              </Form.Group>
            </div>
          </Form>

          <div className="d-grid mt-4">
            <Button variant="primary" type="submit"   onClick={handleSubmit}>
              {!loading ? (
                "Reset Password"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
          <p className="text-center mt-3">
            Back to  
            <a href="/" className="ms-2" style={{ color: "rgb(43, 51, 138) " }}>
                Login
            </a>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default ResetPassword;
