
import { Button, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect , useState} from 'react';
import axios from "axios";



function EmailActivation() {
    const [token ,setToken] =useState("");
    const navigate = useNavigate();
    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const tokens = searchParams.get('token');
        setToken(tokens);
        console.log(token);
    
       
      }, []);
    
      const activate =() =>{
      axios
        .post("https://datastormapi.azurewebsites.net/User/Activate",{
            token : token
        })
        .then(res =>{
          console.log(res);
          navigate("/");
        })
        
      }
     

  return (
    <div>
      <Container>
        <div className="logo d-flex">
          <img
            src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
            alt="logo"
            className="logosmalls"
          />
          <h3 className="ms-3 mt-2">DataStorm</h3>
        </div>
           
           <div className="content">
              <h4>Congratulations, your account has been successfully activated.</h4>
              <p>Please click the link below to proceed.</p>
       <Link><a aria-current="page" onClick={activate}>click to login</a> </Link> 

           </div>
      </Container>
    </div>
  );
}

export default EmailActivation;
