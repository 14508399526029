import React, { useState } from "react";
import { Button, Form, Container } from "react-bootstrap";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faLongArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useEffect } from "react";

var adminId = 0;
var permissionId = [0];

function SigninForm() {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const [signUp, setSignUp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vendorValue, setVendorValue] = useState([]);

  useEffect(() => {
    axios.get("https://datastormapi.azurewebsites.net/Vendor").then((res) => {
      setVendorValue(res.data);
    });
  }, []);

  const handleDropdownChange = (vendor) => {
    if (selectedVendors.includes(vendor)) {
      setSelectedVendors(selectedVendors.filter((v) => v !== vendor));
    } else {
      setSelectedVendors([...selectedVendors, vendor]);
      console.log(selectedVendors);
    }
  };

  const transformedVendors = selectedVendors.map((vendor) => ({
    vendor: vendor,
  }));

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };


  const responseGoogle = (response) => {
    console.log(response); // Handle the Google login response here
  };

  const responseFacebook = (response) => {
    console.log(response); // Handle the Facebook login response here
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(true);
      setLoading(true);

      if (email && firstName && lastName && password) {
        axios
          .post("https://datastormapi.azurewebsites.net/User", {
            email: email,
            firstName: firstName,
            lastName: lastName,
            password: password,
            vendors: transformedVendors,
            adminId: adminId,
            permissionId: permissionId,
          })
          .then((res) => {
            setSignUp(res.data);
            if (res.data == "User Already Exists in the Database") {
              notification.warning({
                message: "Warning",
                description: "User Already Exists in the Database!",
              });

              setLoading(false);
            } else if (res.data == "User Created Successfully") {
              notification.success({
                message: "Success",
                description: "User Created Successfully!",
              });
              setLoading(false);
              navigate("/SignupGuide");

              setConfirmpassword("")
            }
          })
          .catch((error) => {
            notification.error({
              message: "Error",
              description: "Somthing Wrong",
            });
            console.error(error);
            setLoading(false);
          });
      } else {
        setLoading(false);
        notification.warning({
          message: "Warning",
          description: "Please fill in all required fields",
        });
      }
    }
  };

  return (
    <Container className="mt-5 mb-5">
      <div className="brand-logo">
        {/* <img
          src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
          alt="logo"
          className="logosmalls"
        /> */}
        {/* <img
            src="/static/media/logo-white.a96fa35203df4d2957ec95142619307e.svg"
            alt="logo"
            className="logolarge"
          /> */}
        <h4 className="logolarge text-center">DataStorm</h4>
      </div>
      <div className="contanier-type1">
        <div className="auth-login-icon google-login-icon">
          <div className="googleicon">
            <GoogleLogin
              clientId="YOUR_GOOGLE_CLIENT_ID"
              buttonText="Continue with Google"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            />
          </div>
        </div>
        <div className="auth-login-icon facebook-login-icon mb-4">
          <div className="facebookicon">
            {/* <FacebookLogin
              appId="YOUR_FACEBOOK_APP_ID"
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              // Use the FaFacebook icon from react-icons/fa
            /> */}
            <img
              src="https://www.logo.wine/a/logo/Facebook/Facebook-f_Logo-Blue-Logo.wine.svg"
              alt="Facebook Logo"
            />
            Continue with Facebook
          </div>
        </div>

        <Form onSubmit={handleSubmit} noValidate validated={validated}>
          <div className="separator mb-4">Or</div>
          <div className="inputs">
            <Form.Group className="mb-3" controlId="validationCustom05">
              <Form.Control
                type="text"
                placeholder="First name"
                value={firstName}
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the First name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 ms-3" controlId="validationCustom0">
              <Form.Control
                type="text"
                placeholder="Last name"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the Last name.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <div className="inputs">
            <div className="mb-0 password-cont  ">
              <Form.Group className="mb-3" controlId="validationCustom07">
                <Form.Control
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Form.Control.Feedback type="invalid">
                  Please fill the Password.
                </Form.Control.Feedback>

                <span
                  className="show-password"
                  onClick={togglePasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isPasswordVisible ? faEye : faEyeSlash}
                  />
                </span>
              </Form.Group>
            </div>
            <div className="mb-0 password-cont ms-3">
              <Form.Group className="mb-3" controlId="validationCustom08">
                <Form.Control
                  type={isConfirmPasswordVisible ? "text" : "password"}
                  placeholder="Confirm Password"
                  required
                  value={confirmpassword}
                  onChange={(e) => {
                    setConfirmpassword(e.target.value);
                    // Check if passwords match in real-time
                    if (password !== e.target.value) {
                      setPasswordMismatch(true);
                    } else {
                      setPasswordMismatch(false);
                    }
                  }}
                />
                  <Form.Control.Feedback type="invalid">
                  Please fill the Password.
                </Form.Control.Feedback>
                {passwordMismatch && (
                  <div className="text-danger" style={{fontSize:" .875em",marginTop:"0.25rem"}}>Passwords do not match.</div>
                )}
                <span
                  className="show-password"
                  onClick={toggleConfirmPasswordVisibility}
                >
                  <FontAwesomeIcon
                    icon={isConfirmPasswordVisible ? faEye : faEyeSlash}
                  />
                </span>
              </Form.Group>
            </div>
          </div>
          <div className="inputs">
            <Form.Group className="mb-3" controlId="validationCustom08">
              <Form.Control
                type="email"
                placeholder="Email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please fill the Email .
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="validationCustom09">
              <Dropdown onSelect={(eventKey) => handleDropdownChange(eventKey)}>
                <Dropdown.Toggle variant="" id="triggerId" className="custom-dropdown-toggle">
                  Vendors
                  <span className="icon ms-1" id="arrorw">
                    &#9663;
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {vendorValue.map((p) => (
                    <Dropdown.Item
                      key={p.id}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDropdownChange(p.vendor);
                      }}
                    >
                      <input
                      className="me-2"
                        type="checkbox"
                        checked={selectedVendors.includes(p.vendor)}
                        onChange={() => handleDropdownChange(p.vendor)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDropdownChange(p.vendor);
                        }}
                      />
                      {p.vendor}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>
          </div>

          <div className="mb-4 mt-4">
            <div className="custom-control custom-checkbox">
              <Form.Label
                className="custom-control-label ml-3"
                htmlFor="customCheck1"
              >
                <div className="cont-s">
                  <p>
                    By clicking ‘Create account’ or ‘continue with Google or
                    Facebook’ you agree to the <a href="/sign-up">DataStorm</a>{" "}
                    and <a href="/sign-up">Privacy policy</a>
                  </p>
                </div>
              </Form.Label>
            </div>
          </div>
          <div className="d-grid">
            <Button variant="primary" type="submit">
              {!loading ? (
                "Create Account"
              ) : (
                <div className="spinner-box">
                  <div className="pulse-container">
                    <div className="pulse-bubble pulse-bubble-1"></div>
                    <div className="pulse-bubble pulse-bubble-2"></div>
                    <div className="pulse-bubble pulse-bubble-3"></div>
                  </div>
                </div>
              )}
            </Button>
          </div>
          <div className="new-create-account">
            <p className="text-center">
              Have an account? <a href="/">Log in</a>
            </p>
          </div>
        </Form>
      </div>
    </Container>
  );
}

export default SigninForm;
