
import React from "react";
import { Container } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


const ComparativeChart = () => {
    const options = {
   
      responsive: true,
      indexAxis: 'y',
      layout:{
        padding:20
      }
   
    };
  
    const labels = ["Other", "Select", "Premier", "Gold"];
  
    const data = {
      labels,
      datasets: [
        {
          label: "Dataset 1",
          data: [5400,5600,1200,150000],
          backgroundColor: "#6C63FF",
          barThickness: 30,
        },
       
      ],
    };
  
    return (
    <>
       
          <Bar
            options={options}
            data={data}
            style={{ height: "700px",width:"500px"}} 
            fluid
          />
     
     </>
    );
  };

export default ComparativeChart