import React, { useState } from 'react'
import { Container } from 'react-bootstrap'

function PasswordGuide() {
    const [email] = useState(localStorage.getItem("email"));
  return (
    <div>
          <Container>
        <div className="logo d-flex">
          <img 
            src="https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://datastorm.ai/&size=50"
            alt="logo"
            className="logosmalls"
          />
          <h3 className="ms-3 mt-2">DataStorm</h3>
        </div>
           
           <div className="content">
              <h4>Check your email to reset the password</h4>
              <p className='mt-3'>Click the password reset link sent to  <a href="#" style={{color:"rgb(43, 51, 138) "}}>{email}.</a></p>
             
              <p>Didn't receive the email? Please check spam folder</p>
              <p>Back to <a href='/' className="ms-2"  style={{color:"rgb(43, 51, 138) "}}>Login</a></p>
     

           </div>
      </Container>
    </div>
  )
}

export default PasswordGuide